import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import getEnvVariables from 'helpers/getEnv';

const API_KEY = process.env.REACT_APP_BUGSNAG_API_KEY;

const initBugTracker = (): void => {
    if (process.env.NODE_ENV === 'development') return;

    if (!API_KEY) return;

    Bugsnag.start({
        apiKey: API_KEY,
        appVersion: process.env.REACT_APP_COMMIT_REF,
        appType: process.env.REACT_APP_NETLIFY_SITE_NAME,
        releaseStage: getEnvVariables().env,
        enabledReleaseStages: ['production', 'demo', 'dev'],
        plugins: [new BugsnagPluginReact()],
    });
};

export default initBugTracker;
