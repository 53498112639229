interface EnvVars {
    apiV2Url: string;
    env: string;
}

const getEnvVariables = (): EnvVars => {
    const windowObj = window as any;
    const apiV2Url = windowObj.REACT_APP_API_URL
        ? windowObj.REACT_APP_API_URL
        : process.env.REACT_APP_API_URL;
    const env = windowObj.REACT_APP_ENVIRONMENT
        ? windowObj.REACT_APP_ENVIRONMENT
        : process.env.REACT_APP_ENVIRONMENT;

    return {
        apiV2Url,
        env,
    };
};

export default getEnvVariables;
